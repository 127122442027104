.App {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: min(50px, 10%);
  font-family: "SometypeMono";
}

.picture_of_me {
  width: 200px;
  max-width: 50%;
  border-radius: 20px;
}

.home_header span {
  margin-left: min(50px, 10%);
  margin-right: min(50px, 10%);
  position: absolute;
}

.home_page {
  background-color: rgba(50, 100, 40);
  padding: min(100px, calc(20px + 8vmin));
}

.home_category_element a {
  color: white;
}

.home_category_element {
  position: relative !important;
}

.background_img {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.background_img_vertical {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  display: none;
  height: 100vh;
  object-fit: cover;
}

.over_img_content {
  margin: 0;
  padding: min(100px, calc(0px + 10vmin));
  background-color: rgba(0, 0, 0, .8);
  border-radius: 50px;
  color: white;
  z-index: 2;
  position: relative;
}

.top_message {
  margin: 50px;
  position: absolute;
  max-width: 23%;
}

.travels_content {
  margin-top: 75px;
  padding: min(50px, calc(8px + 3vmin));
  border-radius: 30px;
  background-color: rgba(50, 100, 40, .6);
}

.travels_content a {
  color: white;
  margin-right: 20px;
  margin-top: 20px;
}

.travels_content h2 {
  margin-top: 0;
}

.contact_url {
  color: white;
  margin: 30px;
  margin-top: 10px;
}

.contact_bar p {
  margin: 0;
  padding: 0;
}

.contact_bar {
  text-align: center;
  position: relative;
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 50px;
  z-index: 3;
  color: white;
}

@media (max-width: 1000px) {

.over_img_content {
  padding: calc(0px + 8vmin);
}

.home_page {
  padding: calc(30px + 5vmin);
  padding-top: calc(20px + 3vmin);
}

}

@media (max-width: 800px) {

.background_img {
  display: none;
}

.background_img_vertical {
  display: block;
}

}

@media (max-width: 600px) {

.over_img_content {
  padding: calc(0px + 12vmin);
}

.home_page {
  padding: calc(10px + 8vmin);
}

.top_message {
  max-width: 100%;
  margin: 0;
  margin-top: 20px;
  text-align: center;
  display: block;
  max-width: 100%;
  position: relative;
}

.travels_content {
  margin-top: 15px;
}

.picture_of_me {
  width: calc(20px + 20vmin);
  display: block;
  margin: auto;
}

.travels_content a {
  margin-right: 10px;
  margin-top: 0px;
  font-size: 12px;
}

}

@media (max-width: 550px) {
  .contact_url {
    margin: 10px;
    font-size: 12px;
    margin-top: 15px;
  }
}


@media (max-width: 400px) {
  .travels_content a {
    display: inline-block;
  }
}
